.resume-ul {
  list-style: none;
}

.resume-ul li:before {
  content: "\2022";
  color: rgb(255, 255, 255);
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.resume-ul li {
  margin-top: 4px;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgb(246, 246, 246);
  }
  
  .separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: .25em;
  }