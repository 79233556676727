.custom-btn1 {
  cursor: pointer;
  user-select: none;
  width: 250px;
  transition: all 0.5s ease;
  color: #e2e2e2;
  border: 3px solid rgb(19, 19, 19);
  font-family: "Montserrat", sans-serif;
  text-align: center;
  line-height: 1;
  font-size: 17px;
  background-color: transparent;
  padding: 10px;
  outline: none;
  border-radius: 25px;
}
.custom-btn1:hover {
  color: rgb(19, 19, 19);
  background-color: #e2e2e2;
}
